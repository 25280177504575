import React from "react";
import "../Header.css";

function Logo() {
  return (
    <div className="logo">
      <h1>FaDO</h1>
    </div>
  );
}

export default Logo;
